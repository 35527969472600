import { http } from '../http-common'

class AnalyticsService {
  ANALYTICS_LABELS = {
    claims_per_courier: { label: 'claims_per_courier', value: 'Claims per courier in the last 30 days' },
    num_open_claims: { label: 'num_open_claims', value: 'Number of open claims' },
    avg_claim_value_last_30_days: { label: 'avg_claim_value_last_30_days', value: 'Average claim value in the last 30 days', unit: '£', unit_before: true },
    settlement_percentage_last_30_days: { label: 'settlement_percentage_last_30_days', value: 'Settlement percentage in the last 30 days', unit: '%' },
    avg_cycle_time_last_30_days: { label: 'avg_cycle_time_last_30_days', value: 'Average cycle time in the last 30 days', unit: ' days' },
    num_shipments_in_transit: { label: 'num_shipments_in_transit', value: 'Number of shipments at risk' },
    total_amount_at_risk: { label: 'total_amount_at_risk', value: 'Total amount at risk', unit: '£', unit_before: true },
    total_amount_commission_last_30_days: { label: 'total_amount_commission_last_30_days', value: 'Total commission in the last 30 days', unit: '£', unit_before: true },
    avg_shipment_value_last_30_days: { label: 'avg_shipment_value_last_30_days', value: 'Average shipment value in the last 30 days', unit: '£', unit_before: true }
  }

  cardsBuilder (cardsRaw) {
    if (!cardsRaw) return []

    const cards = []
    for (const card of cardsRaw) {
      cards.push(
        {
          key: card.key,
          description: this.ANALYTICS_LABELS[card.key].value,
          unit: this.ANALYTICS_LABELS[card.key].unit ?? null,
          unit_before: this.ANALYTICS_LABELS[card.key].unit_before ?? null,
          value: card.value
        }
      )
    }

    return cards
  }

  getColorScheme (size) {
    const backgroundColors = ['rgba(249, 115, 22, 0.2)', 'rgba(6, 182, 212, 0.2)', 'rgb(107, 114, 128, 0.2)', 'rgba(139, 92, 246, 0.2)']
    const borderColors = ['rgb(249, 115, 22)', 'rgb(6, 182, 212)', 'rgb(107, 114, 128)', 'rgb(139, 92, 246)']

    const resultBackgroundColors = []
    const resultBorderColors = []

    for (let i = 0; i < size; i++) {
      resultBackgroundColors.push(backgroundColors[i % backgroundColors.length])
      resultBorderColors.push(borderColors[i % borderColors.length])
    }

    return {
      backgroundColor: resultBackgroundColors,
      borderColor: resultBorderColors
    }
  }

  graphsBuilder (graphsRaw) {
    if (!graphsRaw) return []

    const graphs = []

    for (const graph of graphsRaw) {
      const labels = []
      const values = []
      for (const data of graph.value) {
        labels.push(data.label)
        values.push(data.value)
      }

      const { backgroundColor, borderColor } = this.getColorScheme(labels.length)

      graphs.push({
        title_lable: this.ANALYTICS_LABELS[graph.key].value,
        labels,
        data: values,
        backgroundColor,
        borderColor
      })
    }

    return graphs
  }

  async fetchAnalytics () {
    const claimResponse = await http.get('claims/analytics/')
    const claimCards = this.cardsBuilder(claimResponse.data?.cards)
    const claimGraphs = this.graphsBuilder(claimResponse.data?.graphs)

    const shipmentResponse = await http.get('shipments/analytics/')
    const shipmentCards = this.cardsBuilder(shipmentResponse.data?.cards)

    return { claimCards, claimGraphs, shipmentCards }
  }
}

export default new AnalyticsService()
