<template>
  <div :class="{ 'no-scroll-wrapper' : !onboardingCompletedFlag }">
    <NavigationBar v-bind="{dashboard: true}"/>

    <div v-if="showSpinner && onboardingCompletedFlag">
      <div class="modal-backdrop fade show"></div>

      <div class="modal d-flex justify-content-center align-items-center">
        <div class="spinner-border text-secondary" role="status" style="width: 3rem; height: 3rem;">
          <span class="visually-hidden"></span>
        </div>
      </div>
    </div>

    <div v-else class="d-flex min-vh-100">
      <!-- Page Container  -->
      <div class="d-flex flex-column" id="page-content-wrapper">
        <!-- Django messages  -->
        <div class="container-fluid pt-7 flex-fill">
          <!-- Page Content  -->
          <div id="content" class="col">
            <div class="tw-flex tw-flex-col tw-gap-2">
              <div class="tw-flex tw-gap-1 tw-items-center">
                <h1 class="tw-mb-0">Shipments</h1>
                <div
                  v-if="protectionStatus.has_paused_insurance"
                  class="tw-text-xs tw-border tw-py-1 tw-px-2 tw-rounded tw-bg-white tw-flex tw-items-center tw-gap-1"
                >
                  <span class="tw-h-2 tw-w-2 tw-bg-yellow-400 tw-rounded-full"></span>
                  <span>
                    No shipments are being insured. Update coverage range in <router-link to="/account">My account</router-link>
                  </span>
                </div>
                <div
                  v-else
                  class="tw-text-xs tw-flex tw-gap-1 tw-items-center tw-border tw-rounded tw-bg-white tw-py-1 tw-px-2"
                >
                  <span>You're insuring orders between £{{ protectionStatus.min_order_value }} and £{{ protectionStatus.max_order_value }}.</span>
                  <span class="tw-text-xs">
                    You can update coverage range in <router-link to="/account">My account</router-link>
                  </span>
                </div>
              </div>
              <div
              v-if="protectionStatus.details && !protectionStatus.details.is_merchant && protectionStatus.details.partner_name"
                class="tw-text-sm tw-flex tw-items-center tw-gap-1"
              >
                <span class="tw-h-2 tw-w-2 tw-bg-yellow-400 tw-rounded-full"></span>
                <span>
                  Your account is being verified by {{ protectionStatus.details.partner_name }}. If you don't receive a confirmation email within 24 hours, please contact our <a href='mailto:hello@withanansi.com' class='protection-status-link'>customer support</a>
                </span>
              </div>
            </div>
            <div class="tw-mt-8">
              <p v-if="policyholder && !policyholder.can_configure_insurance" class="mt-6"><strong>Tip:</strong> You can find your <strong>Policyholder ID</strong> in <router-link :to="'/account'">My Account</router-link>.</p>

              <div class="card w-100 shadow-light">

                <!-- Code for active orders-->
                <div class="order-table align-items-center d-flex justify-content-center p-5 tab-content" id="myTabContent" data-test="home-dashboard-table">
                  <div class="tab-pane table-responsive show active" id="active_orders_content" role="tabpanel" aria-labelledby="active-tab">
                    <!-- FULFILLMENTS -->
                    <div id="active-orders-table" class="font-size-sm">
                      <div class="tw-flex tw-justify-end tw-items-center">
                        <div class="dropdown">
                          <button class="tw-border p-2 tw-rounded" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-sort text-purple-anansi"></i> Sort By: {{searchParameters.descriptionOrderByText}}
                          </button>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <span class="dropdown-item text-muted">SORT BY:</span>
                            <a @click="orderBy('order_number', 'Order Number', searchParameters.order)" class="dropdown-item" :class="searchParameters.order_by === 'order_number' ? 'active' : ''">Order Number</a>
                            <a @click="orderBy('created_at', 'Created at', searchParameters.order)"  class="dropdown-item" :class="searchParameters.order_by === 'created_at' ? 'active' : ''">Created at</a>
                            <a @click="orderBy('total_price', 'Total Price', searchParameters.order)"  class="dropdown-item" :class="searchParameters.order_by === 'total_price' ? 'active' : ''">Total Price</a>
                            <a @click="orderBy('expected_delivery', 'Expected Delivery', searchParameters.order)"  class="dropdown-item" :class="searchParameters.order_by === 'expected_delivery' ? 'active' : ''">Expected Delivery</a>
                            <hr/>
                            <span class="dropdown-item text-muted">ORDER:</span>
                            <a @click="orderBy(searchParameters.order_by , searchParameters.descriptionOrderByText , 'asc')"  class="dropdown-item" :class="searchParameters.order === 'asc' ? 'active' : ''">Ascending</a>
                            <a @click="orderBy(searchParameters.order_by , searchParameters.descriptionOrderByText , 'desc')" class="dropdown-item" :class="searchParameters.order === 'desc' ? 'active' : ''">Descending</a>
                          </div>
                        </div>

                        <button data-test='display-filter-button' @click="filters = !filters" class="dropdown-toggle tw-border tw-rounded tw-ml-4 tw-p-2" type="button">
                          <i class="fa fa-filter text-purple-anansi" aria-hidden="true"></i> Filters
                        </button>

                        <!-- Prepend -->
                        <div class="tw-p-2 tw-border tw-ml-4 tw-flex tw-gap-2 tw-items-center tw-rounded">
                          <i class="fas fa-search"></i>
                          <input v-model="searchParameters.search_text" type="text" class="tw-min-w-64 placeholder:tw-text-gray-500"
                          aria-label="Search your orders by any keyword" placeholder="Search your orders by any keyword">
                        </div>
                        <!-- Input -->
                        <button @click="searchBox" class="tw-bg-purple-600 hover:tw-bg-purple-700 tw-text-white tw-ml-4 tw-p-2 tw-rounded"> Search </button>
                      </div>

                      <template v-if="filters">
                      <div class="row mx-0 text-left align-items-left">
                        <div class="col-md-12 text-center">
                          <hr class="w-100 mt-5 mb-5" />
                        </div>
                      </div>
                      <div class="row mx-0 text-left align-items-left">
                          <div class="col-md-4">
                            <span class="h4">Orders creation date</span>
                            <hr/>
                          </div>
                          <div class="col-md-4">
                            <span class="h4">Total price between ranges</span>
                            <hr/>
                          </div>
                          <div class="col-md-4">
                            <span class="h4">Expected delivery date</span>
                            <hr/>
                          </div>
                        </div>
                        <div class="row mx-0">
                        <div class="col-md-2">
                          Orders created after:
                          <datepicker
                              v-model="searchParameters.created_at_after"
                              :upperLimit="searchParameters.created_at_before"
                              :clearable="true"
                            />
                        </div>
                        <div class="col-md-2">
                          Orders created before:
                          <Datepicker
                            v-model="searchParameters.created_at_before"
                            :lowerLimit="searchParameters.created_at_after"
                            :clearable="true"
                          />
                        </div>
                        <div class="col-md-4">
                          <div class="my-1">
                            Price above:
                            <input type="number" v-model="searchParameters.total_price_above_inclusive">
                          </div>
                          <div class="my-1">
                            Price below:
                            <input type="number" v-model="searchParameters.total_price_below_inclusive">
                          </div>
                          <div class="my-1">
                            Covered value above:
                            <input type="number" v-model="searchParameters.covered_value_above_inclusive">
                          </div>
                          <div class="my-1">
                            Covered value below:
                            <input type="number" v-model="searchParameters.covered_value_below_inclusive">
                          </div>
                        </div>

                        <div class="col-md-2">
                          Delivery date after:
                          <Datepicker
                              v-model="searchParameters.expected_delivery_after"
                              :upperLimit="searchParameters.expected_delivery_before"
                              :clearable="true"
                            />
                        </div>
                        <div class="col-md-2">
                          Delivery date before:
                          <Datepicker
                            v-model="searchParameters.expected_delivery_before"
                            :lowerLimit="searchParameters.expected_delivery_after"
                            :clearable="true"
                          />
                        </div>
                        </div>
                        <div class="row mx-0">
                          <div class="col-md-12 text-center">
                            <hr class="w-100 mt-5 mb-5" />
                          </div>
                        <div class="col-md-2">
                          Tracking company:
                          <form class="form-group">
                            <div v-for="(carrier, index) in fulfillments.carriers" v-bind:key="index" class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" v-bind:id=carrier
                              v-model="searchParameters.tracking_company"
                              v-bind:true-value=carrier
                              false-value="">
                              <label class="custom-control-label align-middle" v-bind:for=carrier >{{carrier}}</label>
                            </div>
                          </form>
                        </div>
                        <div class="col-md-2">
                          <form class="form-group">
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="insuranceRange" v-model="searchParameters.inside_insurance_range" true-value="outside" false-value="">
                              <label class="custom-control-label align-middle" for="insuranceRange">Show orders outside insurance range</label>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="eligibleCountry" v-model="searchParameters.in_eligible_country" true-value="show_not_supported" false-value="">
                              <label class="custom-control-label align-middle" for="eligibleCountry">Show orders in unsupported countries</label>
                            </div>
                          </form>
                        </div>
                        <div class="col-md-2">
                          Order status:
                          <form class="form-group">
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="Pending"
                              v-model="searchParameters.status"
                              true-value="PENDING"
                              false-value="">
                              <label class="custom-control-label align-middle" for="Pending">Pending</label>
                            </div>

                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="InTransit"
                              v-model="searchParameters.status"
                              true-value="IN_TRANSIT"
                              false-value="">
                              <label class="custom-control-label align-middle" for="InTransit">In Transit</label>
                            </div>

                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="Delivered"
                              v-model="searchParameters.status"
                              true-value="DELIVERED"
                              false-value="">
                              <label class="custom-control-label align-middle" for="Delivered">Delivered</label>
                            </div>

                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="Lost"
                              v-model="searchParameters.status"
                              true-value="LOST"
                              false-value="">
                              <label class="custom-control-label align-middle" for="Lost">Lost</label>
                            </div>

                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="TrackingUnavailable"
                              v-model="searchParameters.status"
                              true-value="UNABLE_TO_TRACK"
                              false-value="">
                              <label class="custom-control-label align-middle" for="TrackingUnavailable">Tracking Unavailable</label>
                            </div>

                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="ReturningToSender"
                              v-model="searchParameters.status"
                              true-value="RETURNING_TO_SENDER"
                              false-value="">
                              <label class="custom-control-label align-middle" for="ReturningToSender">Returning to sender</label>
                            </div>

                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="ReturnedToSender"
                              v-model="searchParameters.status"
                              true-value="RETURNED_TO_SENDER"
                              false-value="">
                              <label class="custom-control-label align-middle" for="ReturnedToSender">Returned to sender</label>
                            </div>

                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="NotTracked"
                              v-model="searchParameters.status"
                              true-value="NOT_TRACKED"
                              false-value="">
                              <label class="custom-control-label align-middle" for="NotTracked">Not tracked</label>
                            </div>

                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="Cancelled"
                              v-model="searchParameters.status"
                              true-value="CANCELLED"
                              false-value="">
                              <label class="custom-control-label align-middle" for="Cancelled">Cancelled</label>
                            </div>

                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="Error"
                              v-model="searchParameters.status"
                              true-value="ERROR"
                              false-value="">
                              <label class="custom-control-label align-middle" for="Error">Error</label>
                            </div>

                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="NoEventsReceived"
                              v-model="searchParameters.status"
                              true-value="NO_EVENTS_RECEIVED"
                              false-value="">
                              <label class="custom-control-label align-middle" for="NoEventsReceived">No Events Received</label>
                            </div>
                          </form>
                        </div>

                        <div class="col-md-2">
                          Policies:
                          <form class="form-group">
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="policyAll" v-model="searchParameters.policy_issued" true-value="all" false-value="">
                              <label class="custom-control-label align-middle" for="policyAll">All</label>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="policyIssued" v-model="searchParameters.policy_issued" true-value="issued" false-value="">
                              <label class="custom-control-label align-middle" for="policyIssued">Policy issued</label>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input data-test="policy-not-issued-filter" type="checkbox" class="custom-control-input" id="policyNotIssued" v-model="searchParameters.policy_issued" true-value="not_issued" false-value="">
                              <label class="custom-control-label align-middle" for="policyNotIssued">Policy not issued</label>
                            </div>
                          </form>
                        </div>
                        <div class="col-md-2">
                          <button data-test="apply-filter-button" @click="filter" class="navbar-btn btn btn-sm btn-secondary ml-2"> Apply Filters </button>
                        </div>
                        </div>
                      <div class="col-md-12 text-center">
                          <hr class="w-100 mt-2" />
                        </div>
                      </template>
                    <!-- TABLE -->
                      <div class="table-responsive mt-5">
                        <TableMessage v-if="this.protectionStatus.has_paused_insurance"
                          type="warning"
                          data-test='table-message-warning'
                          @buttonClick="clickShowCoverageModal"
                        >
                          <template v-slot:text>
                            You are currently not insuring any shipments
                          </template>
                        </TableMessage>

                        <CoverageRangeModal
                          :min_order_value="protectionStatus.min_order_value"
                          :max_order_value="protectionStatus.max_order_value"
                          :excluded_courier_list="excluded_courier_list"
                          :show="showCoverageModal"
                          @close="() => showCoverageModal = false"
                          @getCoverageInfo="getProtectionStatus"
                          @getExcludedCouriers="getExcludedCouriers"
                        />

                        <table data-test='home-dashboard-table' id="active-orders-table" role="table" aria-busy="false" aria-colcount="8" class="table b-table table-striped b-table-stacked-md">
                          <thead role="rowgroup" class="">
                            <tr role="row" class="">
                              <th role="columnheader" scope="col" tabindex="0" aria-colindex="1" aria-sort="ascending" class="">
                                <div>
                                  Order Number <a><i class="fas fa-info-circle fa-sm tooltip show cursor-pointer" aria-hidden="true" data-toggle="tooltip" data-placement='right' title="The number of the order, as provided by your system" style="z-index: 990;"></i></a>
                                </div>
                                <span class="sr-only">
                                  (Click to sort Descending)
                                </span>
                              </th>
                              <th role="columnheader" scope="col" tabindex="0" aria-colindex="2" aria-sort="none" class="">
                                <div>
                                  Created at
                                </div>
                                <span class="sr-only">
                                  (Click to sort Ascending)
                                </span>
                              </th>
                              <th role="columnheader" scope="col" tabindex="0" aria-colindex="3" aria-sort="none" class="">
                                <div>Total Price <a><i class="fas fa-info-circle fa-sm tooltip show cursor-pointer" aria-hidden="true" data-toggle="tooltip" data-placement='right' title="Total price of the order"></i></a></div>
                                <span class="sr-only">
                                  (Click to sort Ascending)
                                </span>
                              </th>
                              <th role="columnheader" scope="col" tabindex="0" aria-colindex="3" aria-sort="none" class="">
                                <div>Total Covered Value<a><i class="fas fa-info-circle fa-sm tooltip show cursor-pointer" aria-hidden="true" data-toggle="tooltip" data-placement='right' title="Total covered value of the order"></i></a></div>
                                <span class="sr-only">
                                  (Click to sort Ascending)
                                </span>
                              </th>
                              <th role="columnheader" scope="col" aria-colindex="4" class="">
                                <div>Policy ref. <a><i class="fas fa-info-circle fa-sm tooltip show cursor-pointer" aria-hidden="true" data-toggle="tooltip" data-placement='right' title="Reference for the policy, if one exists"></i></a></div>
                                <span class="sr-only">
                                  (Click to clear sorting)
                                  </span>
                              </th>
                              <th role="columnheader" scope="col" aria-colindex="5" class="">
                                <div>Premium <a><i class="fas fa-info-circle fa-sm tooltip show cursor-pointer" aria-hidden="true" data-toggle="tooltip" data-placement='right' title="Premium value due (including taxes), split by order number"></i></a></div>
                                <span class="sr-only">
                                  (Click to clear sorting)
                                  </span>
                              </th>
                              <th role="columnheader" scope="col" aria-colindex="6" class="">
                                <div>Tracking Number <a><i class="fas fa-info-circle fa-sm tooltip show cursor-pointer" aria-hidden="true" data-toggle="tooltip" data-placement='right' title="The tracking number of your shipment, as provided by your system"></i></a></div>
                                <span class="sr-only">
                                  (Click to clear sorting)
                                </span>
                              </th>
                              <th role="columnheader" scope="col" aria-colindex="7" class="">
                                <div>Tracking Company <a><i class="fas fa-info-circle fa-sm tooltip show cursor-pointer" aria-hidden="true" data-toggle="tooltip" data-placement='right' title="Company used for tracking"></i></a></div>
                                <span class="sr-only">
                                  (Click to clear sorting)
                                </span>
                              </th>
                              <th role="columnheader" scope="col" aria-colindex="8" class="">
                                <div>Status <a><i class="fas fa-info-circle fa-sm tooltip show cursor-pointer" aria-hidden="true" data-toggle="tooltip" data-placement='right' title="Status of the shipment, if we are able to track it"></i></a></div>
                                <span class="sr-only">
                                  (Click to clear sorting)
                                </span>
                              </th>
                              <th role="columnheader" scope="col" aria-colindex="9" class="">
                                <div>Expected Delivery <a><i class="fas fa-info-circle fa-sm tooltip show cursor-pointer" aria-hidden="true" data-toggle="tooltip" data-placement='right' title="Date of the expected delivery, if available"></i></a></div>
                                <span class="sr-only">
                                  (Click to clear sorting)
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody v-if="fulfillments.items && fulfillments.items.length" role="rowgroup">
                            <tr role="row" v-for="fulfilment in fulfillments.items" v-bind:key="fulfilment.order_number"
                            aria-rowindex="{{fulfilment.item_number}}" data-test="home-dashboard-fulfillment-row">
                              <td aria-colindex="1" data-label="Order Number" role="cell" class="">
                                <div>
                                  <a
                                    v-if="fulfilment.order_external_admin_url"
                                    v-bind:href=fulfilment.order_external_admin_url
                                    @click.left="onOrderClick(fulfilment.order_number)"
                                    @click.middle="onOrderClick(fulfilment.order_number)"
                                    rel="noopener"
                                    target="_blank"
                                    class=""
                                  >
                                    #{{fulfilment.order_number}}
                                  </a>
                                  <p v-else>#{{fulfilment.order_number}}</p>
                                </div>
                              </td>
                              <td aria-colindex="2" data-label="Created at" role="cell" class="">
                                <div>{{ fulfilment.created_at === undefined ? '-' : fulfilment.created_at }}</div>
                              </td>
                              <td aria-colindex="3" data-label="Total Price" role="cell" class="">
                                <div>GBP {{ fulfilment.total_price === undefined ? '-' : fulfilment.total_price }}</div>
                              </td>
                              <td aria-colindex="3" data-label="Total Covered Value" role="cell" class="">
                                <div>GBP {{ fulfilment.covered_value === undefined ? '-' : fulfilment.covered_value }}</div>
                              </td>
                              <td aria-colindex="4" data-label="Policy ref." role="cell" class="">
                                <div class="text-secondary">
                                    {{ fulfilment.policy_ref === undefined ? '-' : fulfilment.policy_ref }}
                                </div>
                              </td>
                              <td aria-colindex="5" data-label="Premium" role="cell" class="">
                                {{ fulfilment.premium === undefined || fulfilment.premium === '-' ? '-' : `GBP ${fulfilment.premium}` }}
                              </td>
                              <td aria-colindex="6" data-label="Tracking Number" role="cell" class="">
                                {{ fulfilment.tracking_number === undefined ? '-' : fulfilment.tracking_number }}
                              </td>
                              <td aria-colindex="7" data-label="Tracking Company" role="cell" class="">
                                <div>{{ fulfilment.tracking_company === undefined ? '-' : fulfilment.tracking_company }}</div>
                              </td>
                              <td aria-colindex="8" data-label="Status" role="cell" class="">
                                <div class="text-secondary">
                                  <!-- STATUS -->
                                  <span
                                    data-toggle="tooltip" data-placement="right"
                                    :title=SHIPMENT_STATUS_TO_LABEL[fulfilment.status] >
                                    {{ SHIPMENT_STATUS_TO_CUSTOMER_FACING_STATUS[fulfilment.status] }}
                                  </span>
                                </div>
                              </td>
                              <td aria-colindex="9" data-label="Expected Delivery" role="cell" class="">
                                <div>{{ fulfilment.expected_delivery === undefined ? '-' : fulfilment.expected_delivery }}</div>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else-if="isFetchingData">
                            <tr>
                              <td colspan="12" >
                                <div class="d-flex mt-4 justify-content-center resource-tables-empty-box">
                                <span class="mr-3">
                                  <i class="fa fa-spinner fa-spin gold-text" style="font-size: 1.3rem"/>
                                </span>
                                <p>Fetching data...</p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td colspan="12">
                                <p class="mt-4 text-center resource-tables-empty-box">It's empty for now but don't worry, all your orders will soon be listed here.</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- END-TABLE -->

                      <!-- PAGINATION -->
                      <ul role="menubar" class="pagination b-pagination pagination-sm" :key="fulfillments.page">
                        <li role="presentation" class="page-item" :class="pagination.firstElement ? '' : 'disabled'">
                          <span v-if="!pagination.firstElement" role="menuitem" class="page-link">«</span>
                          <button v-else role="menuitem" type="button" tabindex="-1" class="page-link" @click="pageSelection(pagination.firstPage)">«</button>
                        </li>
                        <li role="presentation" class="page-item" :class="pagination.previusElement ? '' : 'disabled'">
                          <span v-if="!pagination.previusElement" role="menuitem" class="page-link" >‹</span>
                          <button v-else role="menuitem" type="button" tabindex="-1" class="page-link" @click="pageSelection(fulfillments.page-1)">‹</button>
                        </li>
                        <li v-for="page in pagination.pages" :key="page.page" role="presentation" class="page-item" :class="page.active ? 'active' : ''">
                          <button role="menuitemradio" type="button" tabindex="0" class="page-link" @click="pageSelection(page.page)">
                            {{page.page}}
                          </button>
                        </li>
                        <li role="presentation" class="page-item" :class="pagination.nextElement ? '' : 'disabled'">
                          <span v-if="!pagination.nextElement" role="menuitem" data-test="home-dashboard-next-page-disabled" class="page-link" >›</span>
                          <button v-else role="menuitem" data-test="home-dashboard-next-page" type="button" tabindex="-1" class="page-link" @click="pageSelection(fulfillments.page+1)">›</button>
                        </li>
                        <li role="presentation" class="page-item" :class="pagination.lastElement ? '' : 'disabled'">
                          <span v-if="!pagination.lastElement" role="menuitem" class="page-link" >»</span>
                          <button v-else role="menuitem" type="button" tabindex="-1" class="page-link" @click="pageSelection(pagination.lastPage)">»</button>
                        </li>
                        <li>
                          <div class="d-flex align-items-center h-100 ml-4">
                            <div class="box">Shipments per page:</div>
                          <div class="dropdown ml-1">
                              <button class="btn btn-outline btn-options dropdown-toggle border-btn-options padding-btn-options" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {{fulfillments.limit}}
                              </button>
                              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a @click="limitElementsByPage(10)" class="dropdown-item" :class="fulfillments.limit === 10 ? 'active' : ''">10</a>
                                <a @click="limitElementsByPage(25)"  class="dropdown-item" :class="fulfillments.limit === 25 ? 'active' : ''">25</a>
                                <a @click="limitElementsByPage(50)"  class="dropdown-item" :class="fulfillments.limit === 50 ? 'active' : ''">50</a>
                              </div>
                              </div>
                              </div>
                        </li>
                      </ul>
                      <div class="d-flex justify-content-end">
                        <button @click="downloadCSV" class="align-right tw-bg-purple-600 hover:tw-bg-purple-700 tw-text-white tw-ml-4 tw-p-2 tw-rounded">Download as CSV</button>
                      </div>
                      <!-- END-PAGINATION -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MainFooter />
    <EmailVerificationModal
      v-if="show_email_verification_email"
      :show="show_email_verification_email"
      @close="() => handleEmailVerificationClose()"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import CoverageRangeModal from '@/components/commons/CoverageRangeModal.vue'
import EmailVerificationModal from '@/components/commons/EmailVerificationModal.vue'
import NavigationBar from '@/components/NavigationBar.vue'
import Datepicker from 'vue3-datepicker'
import MainFooter from '@/components/MainFooter.vue'
import DashboardDataService from '@/services/DashboardDataService.js'
import FilterByCourierService from '@/services/FilterByCourierService.js'
import TableMessage from '@/components/commons/TableMessage.vue'
// import normalizeGetFulfilmentsResponse from '@/serializers/fulfilments.js'
import PolicyholderDataService from '@/services/PolicyholderDataService.js'
import { useMainStore } from '@/stores'
import { SHIPMENT_STATUS_TO_LABEL, SHIPMENT_STATUS_TO_CUSTOMER_FACING_STATUS } from '@/utils/variables'

export default {
  name: 'DashboardView',
  title: 'Anansi - Dashboard',
  components: {
    CoverageRangeModal,
    EmailVerificationModal,
    NavigationBar,
    MainFooter,
    Datepicker,
    TableMessage
  },
  data () {
    return {
      SHIPMENT_STATUS_TO_LABEL,
      SHIPMENT_STATUS_TO_CUSTOMER_FACING_STATUS,
      isFetchingData: false,
      showCoverageModal: false,
      closeEmailVerificationModal: false,
      filters: false,
      policyholder: null,
      pagination: {
        pages: [
          {
            page: 1,
            active: true
          }
        ],
        activePage: 1,
        lastPage: 1,
        firstPage: 1,
        firstElement: false,
        previusElement: false,
        nextElement: false,
        lastElement: false,
        previusDots: false,
        nextDots: false
      },
      searchParameters: {
        page: 1,
        limit: 10,
        order: 'desc',
        order_by: 'order_number',
        search_text: undefined,
        inside_insurance_range: undefined,
        in_eligible_country: undefined,
        created_at_before: undefined,
        created_at_after: undefined,
        total_price_below_inclusive: undefined,
        total_price_above_inclusive: undefined,
        covered_value_below_inclusive: undefined,
        covered_value_above_inclusive: undefined,
        tracking_company: undefined,
        status: undefined,
        expected_delivery_before: undefined,
        expected_delivery_after: undefined,
        policy_issued: 'all',
        descriptionOrderByText: 'Order Number'
      },
      // Default values in case backend fails
      protectionStatus: {
        confirmed_uk: false,
        details: undefined,
        min_order_value: 0,
        max_order_value: 0,
        has_paused_insurance: true
      },
      excluded_courier_list: [],
      fulfillments: {
        items: undefined,
        page: undefined,
        limit: undefined,
        total_items: undefined,
        carriers: undefined
      },
      showSpinner: true,
      emailResendAllowed: true,
      store: useMainStore()
    }
  },
  async beforeMount () {
    // The onboarding completed watch will load the data when onboarding is complete.
    if (this.onboardingCompletedFlag) {
      await this.loadDashboardData()

      this.protectionStatus.details && !this.protectionStatus.details.account_verified
        ? this.showEmailVerificationModal = true
        : this.showEmailVerificationModal = false
    } else {
      this.showEmailVerificationModal = false
      return DashboardDataService.getOnBoardingStatus().then(response => {
        if (response.data.onboarding_completed) {
          // This should trigger data being loaded
          this.store.setCompletedOnboarding()
        }
      }).catch(error => {
        if (error.status === 403) {
          this.$router.push('LogOut')
        }
      })
    }
  },
  methods: {
    onOrderClick (orderNumber) {
      return true
    },
    generatePagination () {
      this.pagination.firstPage = 1
      this.pagination.lastPage = Math.ceil(parseFloat(this.fulfillments.total_items) / parseFloat(this.fulfillments.limit))
      if (this.fulfillments.page > 1) {
        this.pagination.firstElement = true
      } else {
        this.pagination.firstElement = false
      }

      if (this.fulfillments.page - 1 >= 1) {
        this.pagination.previusElement = true
      } else {
        this.pagination.previusElement = false
      }

      if (this.fulfillments.page + 1 <= this.pagination.lastPage) {
        this.pagination.nextElement = true
      } else {
        this.pagination.nextElement = false
      }

      if (this.fulfillments.page < this.pagination.lastPage) {
        this.pagination.lastElement = true
      } else {
        this.pagination.lastElement = false
      }

      if (this.fulfillments.page - 2 > 1) {
        this.pagination.previusDots = true
      } else {
        this.pagination.previusDots = false
      }

      if (this.fulfillments.page + 2 < this.pagination.lastPage) {
        this.pagination.nextDots = true
      } else {
        this.pagination.nextDots = false
      }

      this.pagination.pages = []
      for (let i = this.fulfillments.page - 1; i <= this.fulfillments.page + 1; i++) {
        if (i < 1 || i > this.pagination.lastPage) {
          continue
        }
        let activatedAux = false
        if (i === this.fulfillments.page) {
          activatedAux = true
        }
        this.pagination.pages.push({ page: i, active: activatedAux })
      }
    },
    // Update the fulfilmments table with the new search parameters
    updateTable () {
      let insideInsuranceRange
      let eligibleCountry
      let policyIssued

      if (this.searchParameters.inside_insurance_range === 'outside') {
        insideInsuranceRange = false
      } else {
        insideInsuranceRange = true
      }

      if (this.searchParameters.in_eligible_country === 'show_not_supported') {
        eligibleCountry = false
      } else {
        eligibleCountry = true
      }

      switch (this.searchParameters.policy_issued) {
        case 'issued':
          policyIssued = true
          break
        case 'not_issued':
          policyIssued = false
          break
      }

      this.fulfillments = {
        items: undefined,
        page: undefined,
        limit: undefined,
        total_items: undefined,
        carriers: undefined
      }

      this.isFetchingData = true

      return DashboardDataService.getFulfilments(
        this.searchParameters.page,
        this.searchParameters.limit,
        this.searchParameters.order,
        this.searchParameters.order_by,
        this.searchParameters.search_text,
        insideInsuranceRange,
        eligibleCountry,
        this.searchParameters.created_at_before,
        this.searchParameters.created_at_after,
        this.searchParameters.total_price_below_inclusive,
        this.searchParameters.total_price_above_inclusive,
        this.searchParameters.covered_value_below_inclusive,
        this.searchParameters.covered_value_above_inclusive,
        this.searchParameters.tracking_company,
        this.searchParameters.status,
        this.searchParameters.expected_delivery_before,
        this.searchParameters.expected_delivery_after,
        policyIssued
      ).then(response => {
        this.fulfillments = response.data
        this.generatePagination()
      }).finally(() => {
        this.isFetchingData = false
      })
    },
    filter () {
      /* eslint-enable camelcase */
      return this.updateTable()
    },
    searchBox () {
      this.searchParameters.page = 1
      return this.updateTable()
    },
    orderBy (orderBy, text, direction) {
      this.searchParameters.order_by = orderBy
      this.searchParameters.descriptionOrderByText = text
      this.searchParameters.order = direction
      return this.updateTable()
    },
    pageSelection (page) {
      this.searchParameters.page = page
      return this.updateTable()
    },
    limitElementsByPage (limit) {
      this.searchParameters.page = 1
      this.searchParameters.limit = limit
      return this.updateTable()
    },
    async loadDashboardData () {
      this.showSpinner = true
      this.generatePagination()
      try {
        await Promise.all([
          this.getPolicyholderData(),
          this.getProtectionStatus(),
          this.updateTable()
        ])

        if (!this.policyholder.can_configure_insurance) {
          this.excluded_courier_list = []
        } else {
          await this.getExcludedCouriers()
        }
      } finally {
        this.showSpinner = false
      }
    },
    getExcludedCouriers () {
      return FilterByCourierService.getExcludedCouriers().then(response => {
        this.excluded_courier_list = response.data
      })
    },
    async getPolicyholderData () {
      const phData = await PolicyholderDataService.getPolicyholder()
      this.policyholder = phData
    },
    getProtectionStatus () {
      return DashboardDataService.getProtectionStatus().then(response => {
        this.protectionStatus = response.data
      })
    },
    resendActivationLink () {
      return DashboardDataService.resendActivationLink().then(response => {
        this.emailResendAllowed = false
      })
    },
    formatDate (date) {
      if (!date) return
      const d = new Date(date)
      let month = '' + (d.getMonth() + 1)
      let day = '' + d.getDate()
      const year = d.getFullYear()

      if (month.length < 2) { month = '0' + month }
      if (day.length < 2) { day = '0' + day }

      return [year, month, day].join('-')
    },
    goToMyAccount () {
      this.$router.push({ name: 'Account' })
    },
    clickShowCoverageModal () {
      if (this.policyholder.can_configure_insurance) this.showCoverageModal = true
    },
    handleEmailVerificationClose () {
      this.closeEmailVerificationModal = true
    },
    downloadCSV () {
      const headers = [
        'Order Number',
        'Created at',
        'Total Price (£)',
        'Total Covered Value (£)',
        'Policy ref.',
        'Premium (£)',
        'Tracking Number',
        'Tracking Company',
        'Status',
        'Expected Delivery'
      ]

      const rows = this.fulfillments.items.map(fulfilment => [
        fulfilment.order_number || '-',
        fulfilment.created_at || '-',
        fulfilment.total_price ?? '-',
        fulfilment.covered_value ?? '-',
        fulfilment.policy_ref || '-',
        fulfilment.premium ?? '-',
        fulfilment.tracking_number || '-',
        fulfilment.tracking_company || '-',
        this.SHIPMENT_STATUS_TO_CUSTOMER_FACING_STATUS[fulfilment.status] || '-',
        fulfilment.expected_delivery || '-'
      ])

      const csvContent = [
        headers.join(','),
        ...rows.map(e => e.join(','))
      ].join('\n')

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

      const timestamp = new Date().toISOString().slice(0, 16)

      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', `anansi_dashboard_${timestamp}.csv`)

      link.click()

      URL.revokeObjectURL(url)
    }
  },
  computed: {
    show_email_verification_email () {
      return this.onboardingCompletedFlag && this.protectionStatus.details && !this.protectionStatus.details.account_verified && !this.closeEmailVerificationModal
    },
    excluded_courier_list_string () {
      if (this.excluded_courier_list && this.excluded_courier_list.length > 0) {
        const length = this.excluded_courier_list.length
        const excludedCouriers = this.excluded_courier_list
        switch (length) {
          case 1:
            return `${excludedCouriers[0].name}`
          case 2:
            return `${excludedCouriers[0].name} and ${excludedCouriers[1].name}`
          default:
            return `${excludedCouriers[0].name}, ${excludedCouriers[1].name}, +${length - 2}`
        }
      }

      return ''
    },
    onboardingCompletedFlag: function (val) {
      return this.store.onBoarding
    }
  },
  watch: {
    // Not ideal. Find a better way to listen to when onboarding is completed so the data is updated
    onboardingCompletedFlag: function (val) {
      // when onboarding is completed, flag is true
      if (val) {
        return this.loadDashboardData()
      }
    }
  }
}
</script>
<style scoped>
.underlined {
  text-decoration: underline;
}
.clickable {
  cursor: pointer;
}
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.no-scroll-wrapper {
  height: 100vh;
  overflow: hidden;
}
</style>
